
window.setAdsRoles = (roles) => {
    window.adsRoles = roles;
}

window.adsNotification = () => {
    dataLayer.push({
        'event': 'ads_notification',
        'roles': JSON.stringify(window.adsRoles),
        'page': window.location.pathname,
    });

    if (typeof ibbAds !== 'undefined' && ibbAds && ibbAds.tag && ibbAds.tag.requestAndPlaceAds) {
        ibbAds.tag.requestAndPlaceAds('');
    }
};


window.reloadAds = (id) => {

    setTimeout(() => {
        let element = document.getElementById(id);

        try {
            if (element && !element.innerHTML && googletag !== 'undefined') {
                googletag.cmd.push(() => {
                    googletag.pubads().refresh();
                });
            }
        } catch (e) {
            console.log(e);
        }

    }, 500);


};